@charset "utf-8";

@import url('https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i');

// Our variables
$base-font-family: "Merriweather", Georgia, 'Times New Roman', Times, serif;
$base-font-size:   1.2em;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.7;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #b80000; //#2a7ae2;
$flash-color:      #FFF8DC;


$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$grey-see-through: rgba(0,0,0,0.75);

// Width of the content area
$content-width:    900px;

$on-palm:          600px;
$on-laptop:        800px;



// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

@mixin content-boundary($spacing: $spacing-unit) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing;
    padding-left: $spacing;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing}));
        max-width:         calc(#{$content-width} - (#{$spacing}));
        padding-right: $spacing / 2;
        padding-left: $spacing / 2;
    }
}


// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;
