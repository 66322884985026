/**
 * Site header
 */
.site-header {
  border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-light;
  position: fixed;
  width: 100%;
  background: $background-color;
  z-index: 10;
}

.site-title {
  font-size: 26px;
  line-height: 56px;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;
  &:visited {
    color: $grey-color-dark;
  }
}

.site-nav {
  padding: 0 0.75em 0;
  .nav-item {
    display: inline-block;
  }
  .page-link {
    color: $text-color;
    line-height: $base-line-height;
	  text-transform: uppercase;
	  font-weight: bold;
    /* Gaps between nav items, but not on the first one*/
    &:not(:first-child) {
	    margin-left: 1.25em;
    }
    &.highlight {
      color: $brand-color;
    }
	  &.current {
	    color: $grey-color;
	  }
  }

  @include media-query($on-palm) {
    .page-link {
	    font-size: 0.65em;
      &:not(:first-child) {
        margin-left: 0.75em;
      }
    }
  }
}

hr {
  background: url(/assets/sep.png) center no-repeat;
  background-size: contain;
  height: 35px;
  border: none;
  outline: none;
}

.blocks {
  text-transform: uppercase;
  font-style: normal;
}

.patreon-button {
  display: block;
  text-align: center;
  img {
    margin: auto;
    width: 165px;
  }
}

/**
 * Site footer
 */
.site-footer {
  @include content-boundary;
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  font-size: 18px;
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  font-size: 15px;
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

.fb-garbage {
  @include content-boundary;
  padding-bottom: 1em;
}

.disqus-wrapper {
  @include content-boundary;
}


/**
 * Page content
 */
.page-content {
  padding: 0;// $spacing-unit 0;
  padding-top: 38px; // height of .site-header
                     overflow: hidden;
}
.post-header {
  @include content-boundary;
  &.full-bleed {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
  }
}
.post-content {
  ul,ol {
    @include content-boundary(6em);
    li {
      margin: 0.8em 0;
    }
  }
  p {
    @include content-boundary;
    &:after {
      display: none;
    }
  }

  p img {
	  float: left;
	  padding: 0 1em 1em 0;
  }
  h3 {
	  clear: both;
  }
}

.figure {
  float: left;
  padding-right: 1em;
  margin-left: 32px;
  width: 30%;
  /* media-query is fucked up and kicks in on anything UNDERNEATH the value passed */
  @include media-query($content-width + 32px) {
    width: 40%;
    margin-left: auto;
  }
  @include media-query($on-palm) {
    box-sizing: border-box;
    width: 100%;
    padding: 1em;
    margin: auto;
    float: none;
  }
  img {
    width: 100%;
  }
  figcaption {
    color: $grey-color-dark;
    font-size: 1em;
    font-style: italic;
  }
}
.home .post-list .figure {
  margin-left: auto;
}

.post .center-quote {
  margin: 2em 0;
  text-align: center;
}

.post .figure {
  padding-left: 1em;
}

.post-index-content {
  @include content-boundary;
  p {
    text-align: justify;
  }
}

.page-heading {
  font-size: 20px;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-footer {
  @include content-boundary;
  border-top: 1px solid $text-color;
  padding-top: 2em;
  font-style: italic;
  font-size: 1em;
}

.signup-form-prompt {
  display: none;
  background: $grey-see-through;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  .form-holder {
    position: relative;
    box-sizing: border-box;
    background: $background-color;
    width: 50%;
    margin: 20vh auto;
    padding: 2em;
    border-radius: 3px;
    @include media-query($on-palm) {
      width: 95%;
    }
    .signup-form {
      margin: 1em;
      padding: 1em;
      form {
        width: 100%;
      }
    }
    .close-button {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  .signup-field-group {
    label {
      text-align: left;
    }
  }
}

.signup-form {
  @include content-boundary;
  text-align: center;
  padding-bottom: 1em;
  &.in-footer {
    padding-top: 1em;
  }
  form {
    text-align: left;
    margin: auto;
    width: 60%;

    label {
      display: block;
      font-style: italic;
      text-align: center;
      line-height: 1.1em;
      padding: .5em 0;
      width: 100%;
    }

    @include media-query($on-palm) {
      width: 100%;
      font-size: 0.9em;
    }
  }
}

.signup-label {
  label {
    width: 100%;
  }
}

.signup-input {
  flex-grow: 2;
}

.signup-field-group {
  display: flex;
  align-items: flex-end;
  input {
    display: block;
    font-size: 1em;
    padding: .5em .5em;
    outline: none;
    width: 100%;
    border-radius: 3px;
    border: 2px solid $grey-color-light;
    -webkit-appearance: none;
  }
}

.signup-button {
  padding-left: 2em;
  input {
    font-size: 1em;
    font-weight: bold;
    display: inline-block;
    background: $brand-color;
    border-radius: 3px;
    border: 1px $brand-color;
    outline: none;
    color: $background-color;
    padding: 0.5em 1em;
    margin-bottom: 2px; /* height of input box border */
    -webkit-appearance: none;
  }
}


.book-image {
  float: left;
  padding-right: 1em;
}

.footer-navigation {
  padding-top: 1em;
  width: 100%;
  display: flex;
  align-content: flex-start;
  .post-header {
    display: inline-block;
    flex-basis: 40%;
    .post-title.with-hero {
      font-size: 1.2em;
      @include media-query($on-palm) {
        font-size: 0.75em;
      }
    }
  }
}

p.download {
  font-style: italic;
  text-align: right;
  font-size: 0.75em;
}

.post-link {
  color: $text-color;
  font-size: 1.2em;
  &:hover {
	  color: white;
  }
}

.rss-subscribe {
  @include content-boundary;
}

.pagination {
  @include content-boundary;
}

/**
 * Posts
 */
img.hero {
  width: 100%;
}

.post-title {
  text-transform: uppercase;
  letter-spacing: -1px;
  line-height: 1.2;
  margin: 0.25em 0;
  &.with-hero {
	  line-height: 1;
	  top: 0.25em;
	  left: 0;
    font-size: 3em;
	  background: $text-color;
	  color: $background-color;
	  position: absolute;
	  margin: 0 0 20px;
    @include media-query($on-palm) {
      font-size: 1.5em;
    }
  }
}

.post-header {
  margin-bottom: $spacing-unit;
  position: relative;
}

.post-meta {
  font-style: italic;
  color: $grey-color;
  &.with-hero {
	  font-style: normal;
	  background: $text-color;
	  color: $background-color;
  }
  .reading-time {
    white-space: nowrap;
  }
}

.post-list {
  .post-meta {
	  font-size: 0.8em;
	  font-style: normal;
	  color: $text-color;
	  &.with-hero {
	    color: $background-color;
	  }
  }
  .post-title {
	  .post-link {
	    text-transform: uppercase;
	    display: inline-block;
	    color: $text-color;
	    line-height: 1.25;
	    &:hover {
		    background: $brand-color;
		    color: $background-color;
		    text-decoration: none;
	    }
	  }
	  &.with-hero {
      top: auto;
	    bottom: 0;
      font-size: 1.5em;
	    .post-link {
		    color: $background-color;
	    }
	  }
  }
  li {
	  margin-bottom: 2em;
	  border-bottom: 2px solid $grey-color-light;
  }
  .post-item li {
    border-bottom: 0;
  }
}


.post-content {
  margin-bottom: $spacing-unit;
  p {
    text-align: justify;
  }
  h2 {
    font-size: 32px;

    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 26px;

    @include media-query($on-laptop) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 20px;

    @include media-query($on-laptop) {
      font-size: 18px;
    }
  }
  blockquote {
    @include content-boundary;
    font-size: 1.2em;
  }
}

.stories {
  @include content-boundary;
}

.story-list {
  list-style: none;
  margin: 0;
}

.story-list-item {
  @extend %clearfix;
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid $grey-color-light;
  .post-title {
	  margin-bottom: 0;
	  text-align: center;
	  display: inline-block;
	  @include media-query($on-palm) {
	    font-size: 1em;
	  }
  }
  .story-thumbnail {
	  float: left;
	  margin-right: 1em;
	  width: 250px;
	  @include media-query($on-palm) {
	    width: 125px;
	  }
  }
  .story-blurb {
	  margin-top: 2em;
  }
  .genre-list {
	  list-style: none;
	  .genre-list-item {
	    display: inline;
	    font-size: 0.8em;
	    font-style: italic;
	    color: $grey-color;
	    &:after {
		    content: ", ";
	    }
	    &:last-child:after {
		    content: "";
	    }
	  }
  }
  .story-read-link {
	  text-transform: uppercase;
	  background: $brand-color;
	  color: $background-color;
	  &.external:after {
	    content: " (external)"
	  }
  }

}



.about {
  img.author-image {
	  float: left;
	  width: 25%;
	  padding: 0 1em 0 0;
  }
}

.confirmation {
  position: relative;
  background: url(/assets/valpo-splash-fuzzy.jpg) no-repeat center center fixed;
  background-color: $text-color;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: $background-color;
  height: 90vh;
  width: 100vw;
  top: 0;
  left: 0;
  text-align: center;
  overflow: hidden;
  margin-bottom: 2em;
  &.is-loaded {
    background: url(/assets/valpo-splash.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    @include media-query($on-palm) {
      background-attachment: scroll;
    }
  }
  .scroll-down {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50px;
    height: 50px;
    .indicator {
      position: absolute;
      left: -50%;
      height: 100%;
      width: 100%;
      background: url(/assets/triangle-down.png) no-repeat center center;
      background-size: cover;
    }
  }
  @include media-query($on-palm) {
    height: 80vh;
    background-attachment: scroll;
  }
}

.confirmation-thank-you {
  @extend %clearfix;
  z-index: 10;
  width: 50%;
  margin: 20vh auto;
  text-align: left;
  padding: 1em;
  background: $grey-see-through;
  h2 {
    text-align: center;
  }
  p {
    min-width: 30%;
  }
  @include media-query($on-palm) {
    margin: 1em auto;
    width: 80%;
    h2 {
      font-size: 1em;
      line-height: 1.2;
    }
    p {
      line-height: 1.2;
      font-size: 0.9em;
    }
  }
}

.buy-box {
  float: left;
  display: flex;
  padding-bottom: 1em;
}

.buy-thumb {
  img {
    width: 165px;
  }
}

.buy-links {
  .amazon {
    padding: 0 0 1em 1em;
  }
  .itunes {
    padding: 1em 0 1em 1em;
  }
}

@-webkit-keyframes flash {
  0% {
    background-color: $flash-color;
    opacity:1;
  }
  100% {
    background-color: $background-color;
  }
}

.flash {
  -webkit-animation-name: flash;
  -webkit-animation-duration: 5000ms;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
}
